<template>
  <transition name="fade" tag="div" class="wrapper" mode="out-in">
    <div class="wrapper" v-if="isLoaded" id="app">
      <BaseDropdown :value="value" width="200" className="language" :defaultName="value ? 'Tiếng Việt' :'English'" @chooseDropdownItem="chooseDropdownItem" />
      <!-- <ToggleLanguage :value="value" @input="changeLanguage" /> -->
      <LandingPage :user="user" />
      <Description :user="user" :content="findSlug('description')" :links="findSlug('links')" />
      <Experience :content="findSlug('experiences')" />
      <Skills :content="findSlug('skills')" />
      <Projects :value=value :content="findSlug('projects')" />
      <Footer :user="user" :links="findSlug('links')" />
    </div>
  </transition>
</template>

<script>
import LandingPage from "./components/LandingPage.vue";
import Description from "./components/Description.vue";
import Experience from "./components/Experience.vue";
import Skills from "./components/Skills.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";
//import ToggleLanguage from "./components/ToggleLanguage.vue";
import BaseDropdown from "./components/BaseDropdown.vue";

import eng from "../data/portfolio-engsub.js"
import viet from "../data/portfolio-vietsub.js" 

export default {
  name: "App",
  components: {
    LandingPage,
    Description,
    Experience,
    Skills,
    Projects,
    Footer,
    //ToggleLanguage,
    BaseDropdown,
  },
  data: () => ({
    isLoaded: false,
    user: {},
    posts: [],
    value: true
  }),
  methods: {
    findSlug(slug) {
      return this.posts.find((item) => {
        return item.slug === slug;
      });
    },
    changeLanguage(){
      this.value = !this.value;
      this.setLanguage(this.value);
    },
    chooseDropdownItem(itemValue){
      this.value = itemValue;
      this.setLanguage(this.value);
    },

    setLanguage(value){
      document.body.classList.add("loading");
      var user_data = {};
      if(!value){
        user_data = eng.objects.find(x => x.slug == "user-data");
        this.posts = eng.objects;       
      }else{         
        user_data = viet.objects.find(x => x.slug == "user-data");
        this.posts = viet.objects;   
      }
      this.user = {
          name: user_data.metadata.value.name,
          info: user_data.metadata.value.info,
          status: user_data.metadata.value.status,
          email: user_data.metadata.value.email,
          phone: user_data.metadata.value.phone,
          province: user_data.metadata.value.province,
          lang: user_data.metadata.value.lang,
          photo: user_data.metadata.value.photo,
          labels: user_data.metadata.labels,
        }
      this.isLoaded = true;
      this.$nextTick(() => document.body.classList.remove("loading"));
    }
  },
  created() {
    this.setLanguage(this.value);
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

#app {
  font-family: Montserrat-Regular, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.wrapper {
  height: 100%;
}
</style>
