<template>
  <div>
    <h3>{{ user.info }}</h3>
    <div class="data"><strong>{{user.labels.email}}</strong> {{ user.email }}</div>
    <div class="data"><strong>{{user.labels.phone}}</strong> {{ user.phone }}</div>
    <div class="data"><strong>{{user.labels.province}}</strong> {{ user.province }}</div>
    <div class="data"><strong>{{user.labels.lang}}:</strong> {{ user.lang }}</div>
    <SocialBar :links="links"/>
  </div>
</template>

<script>
import SocialBar from './SocialBar.vue'

export default {
    name: 'PersonnalCard',
    props: ['user', 'links'],
    components: {
        SocialBar,
    }
}
</script>

<style scoped lang="scss">
    @import '@/styles/constants.scss';

    .data {
        margin-bottom: 15px;
    }
</style>