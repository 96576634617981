export default {
    "objects": [{
            "slug": "projects",
            "title": "projects",
            "metadata": {
                "description": "These are my projects",
                "title": "Projects",
                "items": [{
                        "title": "MISA Mimosa Online",
                        "color": "green",
                        "tag": "Software",
                        "image": "mimosa.png",
                        "content": "The software fully meets the administrative and non-business accounting operations, automatically updating the latest state regulations promptly and comprehensively, and effectively managing the issuance of invoices.",
                        "date": "2022-6-14"
                    },
                    {
                        "title": "MISA BumasTW",
                        "color": "green",
                        "tag": "Software",
                        "image": "bumas.png",
                        "content": "As an effective support tool, it helps lower-level estimating units, governing units, and financial agencies to accurately manage and synthesize state budget estimates for the entire sector and the central government. It promptly serves the direction and administration of leaders at all levels.",
                        "date": "2021-6-14"
                    },
                    {
                        "title": "Employee Management",
                        "color": "green",
                        "tag": "Mini project",
                        "image": "employee.png",
                        "content": "Is a module of MISA AMIS Accounting software. <br>" +
                            "The website allows users to perform basic features such as: <br>" +
                            " + Display a list of employee information. <br>" +
                            " + Add, edit, delete, duplicate, view employee information. <br>" +
                            " + Search employee information by employee code, employee name and phone number. <br>" +
                            " + Refresh the page, reload the data. <br>" +
                            "and advanced features like: <br>" +
                            " + Delete multiple employee information at the same time. <br>" +
                            " + Export employee information list to excel file. <br>" +
                            " + Paging, select the number of records on 1 page. <br>",
                        "date": "2021-8-12"
                    },
                    {
                        "title": "Goods Management",
                        "color": "purple",
                        "tag": "Mini project",
                        "image": "eshop.png",
                        "content": "Is a module of MISA Eshop software. <br>" +
                            "The website allows users to perform basic features such as: <br>" +
                            " + Display a list of goods information. <br>" +
                            " + Add, edit, delete, duplicate, view goods information. <br>" +
                            " + Refresh the page, reload the data. <br>" +
                            "and advanced features like: <br>" +
                            " + Search, filter, sort goods information by column. <br>" +
                            " + Paging, select the number of records on 1 page. <br>" +
                            " + Can select goods images when adding, editing, duplicating goods information. <br>",
                        "date": "2021-9-12"
                    }
                ],
            }
        },
        {
            "slug": "skills",
            "title": "skills",
            "metadata": {
                "items": {
                    "languages": [{
                            "title": "C",
                            "img": "C.png"
                        },
                        {
                            "title": "C++",
                            "img": "cplusplus.png"
                        },
                        {
                            "title": "C#",
                            "img": "Csharp.png"
                        },
                        {
                            "title": "Java",
                            "img": "java.png"
                        },
                        {
                            "title": "JavaScript",
                            "img": "js.png"
                        }
                    ],
                    "frameworks": [{
                            "title": "VueJS",
                            "img": "vuejs.png"
                        },
                        {
                            "title": "jQuery",
                            "img": "jquery.png"
                        },
                        {
                            "title": "ASP.NETCore",
                            "img": "dotnet.png"
                        }
                    ],
                    "databases": [{
                            "title": "MySQL",
                            "img": "mysql.png"
                        },
                        {
                            "title": "SQL Server",
                            "img": "sqlserver.png"
                        }
                    ],
                    "operations": [{
                            "title": "Docker",
                            "img": "docker.png"
                        },
                        {
                            "title": "Kubernetes",
                            "img": "k8s.png"
                        },
                        {
                            "title": "Jenkins",
                            "img": "jenkins.png"
                        },
                        {
                            "title": "ArgoCD",
                            "img": "argocd.png"
                        },
                    ],
                    "others": [{
                            "title": "HTML",
                            "img": "html.png"
                        },
                        {
                            "title": "CSS",
                            "img": "css.png"
                        },
                        {
                            "title": "Linux",
                            "img": "linux.png"
                        },
                        {
                            "title": "Git/Github",
                            "img": "Octocat.png"
                        }
                    ]
                },
                "labels": {
                    "languages": "Languages",
                    "frameworks": "Frameworks",
                    "databases": "Databases",
                    "operations": "Operations",
                    "others": "Others",
                },
                "title": "Skills",
                "description": "Here is my skills"
            }
        },
        {
            "slug": "links",
            "title": "links",
            "metadata": {
                "image": "",
                "facebook": "https://www.facebook.com/pearl.iscoming",
                "instagram": "https://www.instagram.com/dominhngoc2611/",
                "linkedin": "https://www.linkedin.com/in/minh-ngọc-đỗ-584a9527a/",
                "github": "https://github.com/Wolfpleasa"
            }
        },
        {
            "slug": "user-data",
            "title": "user-data",
            "metadata": {
                "value": {
                    "name": "Đỗ Minh Ngọc",
                    "info": "Contact Information",
                    "status": "Full-Stack Web Developer",
                    "email": "dominhngoc2611@gmail.com",
                    "phone": "+84 982 062 524",
                    "province": "Vinh Phuc, Viet Nam",
                    "lang": "Vietnamese, English",
                    "photo": "photo.jpg"
                },
                "labels": {
                    "email": "E-mail:",
                    "phone": "Phone:",
                    "province": "Province:",
                    "lang": "Languages:",
                }
            }
        },
        {
            "slug": "description",
            "title": "description",
            "metadata": {
                "pres_second": "The purpose of this portfolio site is to introduce my programming skills, learn new technologies and open up new job opportunities",
                "pres_first": "I'm a Full-Stack Web Developer. Although IT is not my passion, it helps me to have a stable career and monthly income",
                "pres_title": "Who am I ?",
                "description": "Hope to know you soon",
                "title": "About Me"
            }
        },
        {
            "slug": "experiences",
            "title": "experiences",
            "metadata": {
                "description": "Professional and academic",
                "title": "Experience",
                "professional": [{
                        "year": "From January 2023",
                        "title": "Devops Engineer - Internship",
                        "content": "Learning Docker, Kubernetes, Jenkins, ArgoCD, etc <br>" +
                            "Ensure the progress of the project's build"
                    },
                    {
                        "year": "From October 2021",
                        "title": "Software Engineer",
                        "content": "Participate in company projects. Continue to improve professional knowledge and work skills <br>" +
                            "Working with .xml, .xlsx, .pdf files. <br>" +
                            "Become proficient in the Composition API and Option API in the VueJS framework"
                    },
                    {
                        "year": "07.2021 - 10.2021",
                        "title": "Fresher",
                        "content": "I created a web application to manage employees and goods myself using VueJS and .Net"
                    }
                ],
                "academic": [{
                    "year": "2017 - 2022",
                    "title": "IT Degree",
                    "content": "Posts and Telecommunications Institute of Technology, VietNam"
                }],
                "work": "Professional",
                "education": "Education",
            }
        }
    ],
    "total": 6
}