<template>
  <div>
    <h3  class="text-center">{{ title }}</h3>
    <div class="m-auto pb-4" v-for="(post, index) in posts" :key="index">
        <AnimateOnVisible name="bounce">
            <img id="imgLogo" class="img-responsive mx-auto d-block" :src="getImgUrl(post.img)" :alt="post.title"/>
            <div id="divAlt" class="altCaption text-center">{{post.title}}</div>           
        </AnimateOnVisible>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExperienceColumn",
  props: ["posts", "title"],
  methods: {
      getImgUrl(img) {
          return require('../assets/img/logo/'+img);
      },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/constants.scss';

  #skills {
      background-color: lighten(map-get($colors, dark), 100%);
  }

  @media(min-width: #{map-get($breakpoints, medium)}) {
      .section-content {
          width: 80%;
          margin: 0 auto;
      }
  }

  img{
      max-width: 120px;
  }

  .altCaption{
      color: map-get($colors, secondary);
      margin-top: 1rem;
  }
</style>
