<template>
  <div :class="[className]">
    <div class="dd-language" :style="cssProps">
      <div
        class="select"
        :tabindex="tabindex"
        v-on:keyup.enter="SelectOnClick"
        v-on:keydown="keydownOnSelect($event)"
        v-on:keyup.space="chooseDropdownItemByKey($event)"
        v-on-clickaway="closeDropDown"
        @click="SelectOnClick"
      >
        <div class="flag">
          <div :class="['ensign', value ? 'bg-vn' : 'bg-en']"></div>
        </div>
        <div class="inp">{{ currentName }}</div>
        <div class="select-arrow">
          <div :class="['arrow', rotate ? 'rot-180' : 'rot-0']"></div>
        </div>
      </div>
      <div
        :class="['dropdown', { 'd-none': dnone }]"
      >
        <slide-up-down :active="active" :duration="1000">
          <div
            v-for="(item, index) in items"
            :key="index"
            :Value="index"  
            :class="['dropdown-item', 'd-flex', index == currentIndex ? 'bg-select' : '']"
            @click="chooseDropdownItem(item.itemName, index, item.itemValue)"
          >
            <div class="tick">
              <div></div>
            </div>
            <div class="content">{{ item.itemName }}</div>
          </div>
        </slide-up-down>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "BaseDropdown",
  mixins: [clickaway],

  props: {
    defaultName: String,
    className: String,
    tabindex: String,
    width: String,
    value: Boolean
  },

  data() {
    return {
      //các department,position
      items: [],
      // quay mũi tên
      rotate: false,
      // ẩn/hiện các item
      dnone: true,
      // tên hiển thị
      currentName: this.defaultName,
      currentIndex: 0,
      //slidedown/up
      active: false,
    };
  },

  computed: {
    cssProps() {
      return {
        '--width': this.width + "px",
      }
    }
  }, 
  methods: {
    /**
     * Hàm ngăn chặn sự kiện mặc định của nút tab, dùng thì mới thực hiện được nút enter
     * Created By:Ngọc 31/08/2021
     */
    keydownOnSelect(event) {
      try {
        if (event.code == "Enter") {
          event.preventDefault();
        }
        if (event.code == "ArrowDown") {
          event.preventDefault();
          this.currentIndex = this.currentIndex < 0 ? -1 : this.currentIndex;
          this.currentIndex = (this.currentIndex + 1) % this.items.length;
        } else if (event.code == "ArrowUp") {
          event.preventDefault();
          this.currentIndex = this.currentIndex < 0 ? 0 : this.currentIndex;
          this.currentIndex =
            this.currentIndex == 0
              ? this.items.length - 1
              : this.currentIndex - 1;
        }
      } catch (err) {
        console.log(err);
      }
    },

    /**
     * Sự kiện chọn 1 lựa chọn bằng phím
     * Created By:Ngọc 31/08/2021
     */
    chooseDropdownItemByKey(event) {
      try {
        if (event.code == "Space") {
          event.preventDefault();
          let item = this.items[this.currentIndex];
          let itemName = item[this.itemName];
          this.chooseDropdownItem(itemName, this.currentIndex);
        }
      } catch (err) {
        console.log(err);
      }
    },

    /**
     * Sự kiện chọn 1 lựa chọn
     * Created By:Ngọc 28/08/2021
     */
    chooseDropdownItem(itemName, index, itemValue) {
      try {
        this.currentIndex = index;
        this.SelectOnClick();
        this.$emit("chooseDropdownItem", itemValue);
        this.$nextTick(() => {
          this.reloadLanguage();
          this.currentName = this.items[index].itemName;
        });
        
      } catch (err) {
        console.log(err);
      }
    },

    /**
     * Sự kiện bấm để hiện/ ẩn dropdown
     * Created By:Ngọc 28/08/2021
     */
    SelectOnClick() {
      this.rotate = !this.rotate;
      this.dnone = !this.dnone;
      this.active = !this.active;
    },

    /**
     * Sự kiện đóng dropdown(sử dụng vue-clickaway)
     * Created By:Ngọc 28/08/2021
     */
    closeDropDown() {
      this.rotate = false;
      this.dnone = true;
      this.active = false;
    },

    reloadLanguage(){
      let me = this;
      me.items = []
      if(this.value){
        me.items=[
          {
            "itemId": 1,
            "itemName": "Tiếng Việt",
            "itemValue": true
          },
          {
            "itemId": 2,
            "itemName": "Tiếng Anh",
            "itemValue": false
          }
        ]
      }else{
        me.items=[
          {
            "itemId": 1,
            "itemName": "Vietnamese",
            "itemValue": true
          },
          {
            "itemId": 2,
            "itemName": "English",
            "itemValue": false
          }
        ]
      }
    }
  },

  created() {
    let me = this;
    me.reloadLanguage();

    
    this.currentName = this.defaultName;
  },

  watch: {},
};
</script>

<style lang="scss">

.language{
  position: fixed;
  top: 20px;
  z-index: 10;
  height: 0px;
  width: 100%;
}

.dd-language{
  float: right;
  margin-right: 20px;
  width: var(--width);
}

.select {
    display: flex;
    width: 100%;
    min-height: 32px;
    border: 1px solid #babec5;
    border-radius: 2px;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
}

.select .inp {
    border: none;
    outline: none;
    line-height: 32px;
    width: calc(100% - 32px);
    cursor: pointer;
}

.select .arrow {
    margin: 8px;
    width: 16px;
    height: 16px;
    background: url("../assets/img/Sprites.64af8f61.svg") no-repeat -560px -359px;
    cursor: pointer;
}

.select .arrow.rot-180 {
    transform: rotate(180deg);
    transition: transform 1s;
}

.select .arrow.rot-0 {
    transform: rotate(0deg);
    transition: transform 1s;
}

.select .select-arrow:hover {
    background-color: #e0e0e0;
}

.flag{
  height: 100%;
  width: 40px;

  .ensign{
    height: 18px;
    width: 18px;
    margin: 7px 0 0 7px;
    border-radius: 9999px;
    box-shadow:  0 2px 4px rgba(0, 0, 0, 0.1);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.bg-vn{
  background-image: url("../assets/img/VN.png");
}

.bg-en{
  background-image: url("../assets/img/EN.jpg");
}

.dropdown {
    width: var(--width);
    /* box-shadow: 0 0 5px 1px #e5e5e5; */
    position: absolute !important;
    background-color: #fff;
    border: 1px solid #bbb;
    box-sizing: border-box;
    z-index: 2;
    height: auto;
    border-radius: 2px;
    top: 32px;
}

.dropdown .dropdown-item {
    width: 100%;
    line-height: 32px;
    padding: 0 !important;
    height: 32px;
    cursor: pointer;

    .tick{
      width: 32px;
      height: 32px;
    }

    &.bg-select{
      .tick{
        div{
          background: url("../assets/img/Sprites.64af8f61.svg") no-repeat -1223px -360px;
          height: 16px;
          width: 16px;
        }
        padding: 8px;
      }
    }
}

.dropdown .dropdown-item:hover {
    background-color: #E9EBEE;
    color: #2ca01c;
}
</style>
