export default {
    "objects": [{
            "slug": "projects",
            "title": "projects",
            "metadata": {
                "description": "Các dự án đã tham gia",
                "title": "Dự án",
                "items": [{
                        "title": "MISA Mimosa Online",
                        "color": "green",
                        "tag": "Phần mềm",
                        "image": "mimosa.png",
                        "content": "Phần mềm đáp ứng đầy đủ các nghiệp vụ kế toán Hành chính sự nghiệp, tự động cập nhật đầy đủ, kịp thời các quy định mới nhất của nhà nước, đáp ứng tốt nghiệp vụ quản lý phát hành hóa đơn.",
                        "date": "2022-9-22"
                    },
                    {
                        "title": "MISA BumasTW",
                        "color": "green",
                        "tag": "Phần mềm",
                        "image": "bumas.png",
                        "content": "Là công cụ hỗ trợ đắc lực giúp đơn vị dự toán cấp dưới, đơn vị chủ quản, cơ quan tài chính thực hiện công tác quản lý, tổng hợp dự toán Ngân sách nhà nước toàn ngành, toàn trung ương một cách chính xác, kịp thời phục vụ cho công tác chỉ đạo điều hành của các cấp lãnh đạo.",
                        "date": "2021-11-5"
                    },
                    {
                        "title": "Quản lý nhân viên",
                        "color": "green",
                        "tag": "Dự án cá nhân",
                        "image": "employee.png",
                        "content": "Là 1 phân hệ của phần mềm MISA AMIS kế toán. <br>" +
                            "Trang web cho phép người dùng thực hiện những tính năng cơ bản như: <br>" +
                            " + Hiển thị danh sách thông tin nhân viên. <br>" +
                            " + Thêm, sửa, xóa, nhân bản, xem thông tin nhân viên. <br>" +
                            " + Tìm kiếm thông tin nhân viên theo mã nhân viên, tên nhân viên và số điện thoại. <br>" +
                            " + Làm mới lại trang, tải lại dữ liệu. <br>" +
                            "và các tính năng nâng cao như: <br>" +
                            " + Xóa nhiều thông tin nhân viên cùng lúc. <br>" +
                            " + Xuất khẩu danh sách thông tin ra tệp excel. <br>" +
                            " + Phân trang, chọn số lượng bản ghi trên 1 trang. <br>",
                        "date": "2021-8-12"
                    },
                    {
                        "title": "Quản lý hàng hóa",
                        "color": "purple",
                        "tag": "Dự án cá nhân",
                        "image": "eshop.png",
                        "content": "Là 1 phân hệ của phần mềm MISA Eshop. <br>" +
                            "Trang web cho phép người dùng thực hiện những tính năng cơ bản như: <br>" +
                            " + Hiển thị danh sách thông tin hàng hóa. <br>" +
                            " + Thêm, sửa, xóa, nhân bản, xem thông tin hàng hóa. <br>" +
                            " + Làm mới lại trang, tải lại dữ liệu. <br>" +
                            "và các tính năng nâng cao như: <br>" +
                            " + Tìm kiếm, lọc, sắp xếp thông tin hàng hóa theo cột. <br>" +
                            " + Phân trang, chọn số lượng bản ghi trên 1 trang. <br>" +
                            " + Có thể chọn ảnh hàng hóa khi thao tác thêm, sửa, nhân bản thông tin hàng hóa. <br>",
                        "date": "2021-9-12"
                    }
                ],
            }
        },
        {
            "slug": "skills",
            "title": "skills",
            "metadata": {
                "items": {
                    "languages": [{
                            "title": "C",
                            "img": "C.png"
                        },
                        {
                            "title": "C++",
                            "img": "cplusplus.png"
                        },
                        {
                            "title": "C#",
                            "img": "Csharp.png"
                        },
                        {
                            "title": "Java",
                            "img": "java.png"
                        },
                        {
                            "title": "JavaScript",
                            "img": "js.png"
                        }
                    ],
                    "frameworks": [{
                            "title": "VueJS",
                            "img": "vuejs.png"
                        },
                        {
                            "title": "jQuery",
                            "img": "jquery.png"
                        },
                        {
                            "title": "ASP.NETCore",
                            "img": "dotnet.png"
                        }
                    ],
                    "databases": [{
                            "title": "MySQL",
                            "img": "mysql.png"
                        },
                        {
                            "title": "SQL Server",
                            "img": "sqlserver.png"
                        }
                    ],
                    "operations": [{
                            "title": "Docker",
                            "img": "docker.png"
                        },
                        {
                            "title": "Kubernetes",
                            "img": "k8s.png"
                        },
                        {
                            "title": "Jenkins",
                            "img": "jenkins.png"
                        },
                        {
                            "title": "ArgoCD",
                            "img": "argocd.png"
                        },
                    ],
                    "others": [{
                            "title": "HTML",
                            "img": "html.png"
                        },
                        {
                            "title": "CSS",
                            "img": "css.png"
                        },
                        {
                            "title": "Linux",
                            "img": "linux.png"
                        },
                        {
                            "title": "Git/Github",
                            "img": "Octocat.png"
                        }
                    ]
                },
                "labels": {
                    "languages": "Ngôn ngữ",
                    "frameworks": "Thư viện",
                    "databases": "Hệ quản trị CSDL",
                    "operations": "Triển khai",
                    "others": "Khác",
                },
                "title": "Kỹ năng",
                "description": "Dưới đây là các kỹ năng của tôi"
            }
        },
        {
            "slug": "links",
            "title": "links",
            "metadata": {
                "image": "",
                "facebook": "https://www.facebook.com/pearl.iscoming",
                "instagram": "https://www.instagram.com/dominhngoc2611/",
                "linkedin": "https://www.linkedin.com/in/minh-ngọc-đỗ-584a9527a/",
                "github": "https://github.com/Wolfpleasa"
            }
        },
        {
            "slug": "user-data",
            "title": "user-data",
            "metadata": {
                "value": {
                    "name": "Đỗ Minh Ngọc",
                    "info": "Thông tin liên hệ",
                    "status": "Lập trình viên website",
                    "email": "dominhngoc2611@gmail.com",
                    "phone": "0982 062 524",
                    "province": "Vĩnh Phúc, Việt Nam",
                    "lang": "Tiếng Việt, Tiếng Anh",
                    "photo": "photo.jpg"
                },
                "labels": {
                    "email": "E-mail:",
                    "phone": "Số điện thoại:",
                    "province": "Tỉnh thành:",
                    "lang": "Ngoại ngữ:",
                }
            }
        },
        {
            "slug": "description",
            "title": "description",
            "metadata": {
                "pres_second": "Mục đích của trang Portfolio này là để giới thiệu các kỹ năng lập trình của tôi, tìm hiểu các công nghệ mới và mở ra các cơ hội mới.",
                "pres_first": "Tôi là một lập trình viên. CNTT tuy không phải là đam mê của tôi nhưng nó giúp tôi có một công việc ổn định và thu nhập hàng tháng.",
                "pres_title": "Tôi là ai ?",
                "description": "Hi vọng sẽ biết tới bạn",
                "title": "Giới thiệu bản thân"
            }
        },
        {
            "slug": "experiences",
            "title": "experiences",
            "metadata": {
                "description": "Chuyên môn và học vấn",
                "title": "Kinh nghiệm",
                "professional": [{
                        "year": "Từ tháng 1 năm 2023",
                        "title": "Devops Engineer - InternShip",
                        "content": "Đang làm và tìm hiểu Docker, Kubernetes, Jenkins, ArgoCD, etc <br>" +
                            "Đảm bảo tiến độ build của dự án."
                    },
                    {
                        "year": "Từ tháng 10 năm 2021",
                        "title": "Software Engineer",
                        "content": "Tham gia vào các dự án của công ty. Tiếp tục nâng cao kiến ​​thức chuyên môn và kỹ năng làm việc. <br>" +
                            "Thao tác với các file các .xml, .xlsx, .pdf <br>" +
                            "Nắm chắc kiến thức của composition API, option API trong thư viện VueJS."
                    },
                    {
                        "year": "07.2021 - 10.2021",
                        "title": "Fresher",
                        "content": "Tự tạo 1 trang web cho phép quản lý thông tin nhân viên và hàng hóa."
                    }
                ],
                "academic": [{
                    "year": "2017 - 2022",
                    "title": "Bằng khá chuyên ngành Công nghệ Thông tin",
                    "content": "Học viên Công nghệ Bưu chính Viễn thông"
                }],
                "work": "Chuyên môn",
                "education": "Học vấn",
            }
        }
    ],
    "total": 6
}