<template>
    <section id="skills">
        <AnimateOnVisible name="fadeDown" :duration="1">
            <Title :title="content.metadata.title" :description="content.metadata.description" />
        </AnimateOnVisible>

        <div class="section-content">
            <AnimateOnVisible name="fadeUp" :duration="1">
            <div class="container-fluid">
                <div class="row d-flex justify-center">
                    <SkillsColumn :posts="content.metadata.items.languages" :title="content.metadata.labels.languages"></SkillsColumn>
                    <SkillsColumn :posts="content.metadata.items.frameworks" :title="content.metadata.labels.frameworks"></SkillsColumn>
                    <SkillsColumn :posts="content.metadata.items.databases" :title="content.metadata.labels.databases   "></SkillsColumn>
                    <SkillsColumn :posts="content.metadata.items.operations" :title="content.metadata.labels.operations"></SkillsColumn>
                    <SkillsColumn :posts="content.metadata.items.others" :title="content.metadata.labels.others"></SkillsColumn>
                </div>
            </div>
            </AnimateOnVisible>
        </div>
    </section>
</template>

<script>
    import Title from './Title.vue'
    import SkillsColumn from './SkillsColumn.vue'
    export default {
        name: 'Skills',
        components: {
            Title,
            SkillsColumn
        },
        props: ['content'],
        methods: {
            getImgUrl(img) {
                return require('../assets/img/logo/'+img);
            },
        },
    }
</script>

<style scoped lang="scss">
    @import '@/styles/constants.scss';

    #skills {
        background-color: lighten(map-get($colors, dark), 100%);
    }

    @media(min-width: #{map-get($breakpoints, medium)}) {
        .section-content {
            width: 80%;
            margin: 0 auto;
        }
    }

    img{
        max-width: 120px;
    }

    .altCaption{
        color: map-get($colors, secondary);
        margin-top: 1rem;
    }

    .justify-center {
        justify-content: space-between;
    }
</style>